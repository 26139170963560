const onClientEntry = () => {
  // @ts-expect-error
  const loader = window.___loader

  if (process.env.NODE_ENV === 'development' || !loader) {
    return
  }

  // @ts-expect-error
  const pagePath = window.pagePath
  const location = window.location

  if (
    pagePath &&
    pagePath !== location.pathname &&
    pagePath !== location.pathname + '/'
  ) {
    const originalLoadPageSync = loader.loadPageSync
    const originalLoadPage = loader.loadPage

    loader.loadPageSync = (path: string) => {
      const cleanPath = path.split('?')[0]
      let pageResources

      if (cleanPath === location.pathname) {
        pageResources = originalLoadPageSync(pagePath)
      } else {
        pageResources = originalLoadPageSync(cleanPath)
      }

      if (pageResources.page) {
        pageResources.page.matchPath = '*'
      }

      return pageResources
    }

    loader.loadPage = (path: string) => {
      const cleanPath = path.split('?')[0]
      let pageResources

      if (cleanPath === location.pathname) {
        pageResources = originalLoadPage(pagePath)
      } else {
        pageResources = originalLoadPage(cleanPath)
      }

      if (pageResources.page) {
        pageResources.page.matchPath = '*'
      }

      return pageResources
    }
  }

  loader.hovering = () => {}
}

const disableCorePrefetching = () => true

export { onClientEntry, disableCorePrefetching }
