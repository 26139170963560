exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-ad-blocker-index-tsx": () => import("./../../../src/templates/AdBlocker/index.tsx" /* webpackChunkName: "component---src-templates-ad-blocker-index-tsx" */),
  "component---src-templates-download-index-tsx": () => import("./../../../src/templates/Download/index.tsx" /* webpackChunkName: "component---src-templates-download-index-tsx" */),
  "component---src-templates-install-index-tsx": () => import("./../../../src/templates/Install/index.tsx" /* webpackChunkName: "component---src-templates-install-index-tsx" */),
  "component---src-templates-landing-index-tsx": () => import("./../../../src/templates/Landing/index.tsx" /* webpackChunkName: "component---src-templates-landing-index-tsx" */),
  "component---src-templates-not-found-index-tsx": () => import("./../../../src/templates/NotFound/index.tsx" /* webpackChunkName: "component---src-templates-not-found-index-tsx" */),
  "component---src-templates-offer-index-tsx": () => import("./../../../src/templates/Offer/index.tsx" /* webpackChunkName: "component---src-templates-offer-index-tsx" */),
  "component---src-templates-static-pages-index-tsx": () => import("./../../../src/templates/StaticPages/index.tsx" /* webpackChunkName: "component---src-templates-static-pages-index-tsx" */),
  "component---src-templates-support-index-tsx": () => import("./../../../src/templates/Support/index.tsx" /* webpackChunkName: "component---src-templates-support-index-tsx" */),
  "component---src-templates-top-points-index-tsx": () => import("./../../../src/templates/TopPoints/index.tsx" /* webpackChunkName: "component---src-templates-top-points-index-tsx" */),
  "component---src-templates-uninstall-index-tsx": () => import("./../../../src/templates/Uninstall/index.tsx" /* webpackChunkName: "component---src-templates-uninstall-index-tsx" */)
}

